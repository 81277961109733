// @mui
import Box from "@mui/material/Box"
import Card from "@mui/material/Card"
import Stack from "@mui/material/Stack"
import CardHeader from "@mui/material/CardHeader"
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  useTheme,
  Typography,
  CircularProgress,
} from "@mui/material"
// components
import { DropzoneDialog } from "mui-file-dropzone"
import { useEffect, useState } from "react"
import PDFViewerDialog from "src/components/pdf/PDFViewerDialog"
import { useUploadedCreditReport } from "src/queries/credit/useUploadedCreditReport"
import { CreditApplication } from "src/types"
import { useUploadCreditReport } from "src/queries/credit/useUploadCreditReport"
import { useDeleteUploadedCreditReport } from "src/queries/credit/useDeleteUploadedCreditReport"
import { enqueueSnackbar } from "notistack"
import CreditViewWidget from "./components/credit-view/credit-view-widget"
import Iconify from "src/components/iconify"
import CreditSafeSearchDialog from "./components/CreditSafeSearchDialog"
import {
  useCreditCredentials,
  useCreditsafePurchasedReport,
  useCreditSafeReport,
} from "src/queries/vendors/useCreditSafeSearch"
import { fShortenNumber } from "src/utils/formatNumber"

import equifax from "./equifax.png"
import creditSafe from "./creditsafe.png"
import experian from "./experian.png"
import FileItem from "../components/file/FileItem"
import { confirm } from "src/components/confirm"
import { info } from "src/utils/logger"
import ExperianSearchDialog from "./components/ExperianSearchDialog"
import {
  useExperianPurchasedReport,
  useExperianReport,
} from "src/queries/vendors/useExperianSearch"
import ExperianPurchasedReportItem from "../components/ExperianPurchasedReportItem"

// ----------------------------------------------------------------------
export default ({ application }: { application: CreditApplication }) => {
  const [showUploadDialog, setShowUploadDialog] = useState(false)
  const [pdfToView, setPdfToView] = useState<string | undefined>(undefined)
  const theme = useTheme()
  const { data: creditReport, refetch } = useUploadedCreditReport(
    application.id || "",
  )
  const [bin, setBin] = useState<string | undefined>(undefined)
  const [connectId, setConnectId] = useState("")
  const [showInsights, setShowInsights] = useState(false)
  const {
    data: report,
    refetch: fetchReport,
    isLoading,
  } = useCreditSafeReport(application.id || "", connectId, false)

  const { refetch: fetchExperianReport, isLoading: isLoadingExperianReport } =
    useExperianReport(application.id || "", bin || "", false)

  const { data: credentials } = useCreditCredentials(
    application.seller?.id || "",
  )

  useEffect(() => {
    if (connectId) {
      fetchReport()
    }
  }, [connectId, fetchReport])

  useEffect(() => {
    if (bin) {
      fetchExperianReport()
        .then((res) => {
          return window.open(res.data)
        })
        .catch((err) => console.log(err))
    }
  }, [bin, fetchExperianReport])

  const [searchDialogOpen, setSearchDialogOpen] = useState<
    "Experian" | "CreditSafe" | undefined
  >(undefined)

  const { data: purchasedExperianReports } = useExperianPurchasedReport(
    application.id || "",
  )

  const { data: purchasedCreditsafeReports } = useCreditsafePurchasedReport(
    application.id || "",
  )

  const { execute } = useUploadCreditReport(application.id || "", () => {
    refetch()
    enqueueSnackbar("Credit report uploaded successfully.", {
      variant: "success",
    })
  })
  const { execute: deleteCreditReport } = useDeleteUploadedCreditReport(() => {
    refetch()
    enqueueSnackbar("Credit report deleted successfully.", {
      variant: "success",
    })
  })

  const renderCredit = (
    <>
      <Stack
        sx={{ typography: "body2" }}
        style={{ margin: "1.5rem 1.5rem 1.5rem 1.5rem" }}
      >
        <Box
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "end",
            gap: "2px",
            marginBottom: "1.5rem",
          }}
        >
          <Button
            variant="outlined"
            onClick={() => {
              setShowUploadDialog(true)
            }}
          >
            Upload Existing Report
          </Button>
        </Box>
        {creditReport && creditReport.length > 0 && (
          <Stack
            direction="column"
            justifyContent="space-evenly"
            alignItems="stretch"
            spacing={2}
          >
            {creditReport?.map((file, index) => (
              <FileItem
                key={index}
                file={file.file}
                fileSize={file.fileSize}
                onDelete={() => {
                  confirm("You are about to delete this report.", {
                    okColor: "error",
                    okLabel: "Delete",
                  })
                    .then(
                      () => {
                        return deleteCreditReport(file.id)
                      },
                      () => {
                        info("cancelled")
                      },
                    )
                    .catch(() => {
                      info("cancelled")
                    })
                }}
                onDownload={() => {
                  window.open(file.file, "_blank")
                }}
                onView={() => {
                  setPdfToView(file.file)
                }}
              />
            ))}
          </Stack>
        )}
      </Stack>
    </>
  )

  return (
    <>
      <CardHeader
        title="Credit Report"
        subheader="Upload or view the credit report of the business."
      />
      <Stack
        sx={{
          px: 3,
          gap: "2rem",
          marginTop: "2rem",
        }}
      >
        <Box
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="subtitle1" sx={{ mb: 2 }}>
            Creditsafe
          </Typography>
          <Box
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "1rem",
              alignItems: "center",
            }}
          >
            <img src={creditSafe} style={{ height: "24px" }} />
          </Box>
        </Box>
        {credentials?.creditSafe?.connected && (
          <>
            Your Creditsafe account is connected.
            <Box
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "end",
                gap: "8px",
              }}
            >
              <Button
                variant="contained"
                onClick={() => {
                  setSearchDialogOpen("CreditSafe")
                }}
              >
                Begin Search
              </Button>
            </Box>
            {credentials?.creditSafe?.connected &&
              purchasedCreditsafeReports && (
                <Stack
                  direction="column"
                  justifyContent="space-evenly"
                  alignItems="stretch"
                  spacing={2}
                >
                  {purchasedCreditsafeReports.map(
                    (report: any, index: number) => (
                      <ExperianPurchasedReportItem
                        key={index}
                        fileName={`Creditsafe Report ${index + 1}`}
                        createdAt={report.createdAt}
                        onView={() => {
                          setPdfToView(report.url)
                        }}
                      />
                    ),
                  )}
                </Stack>
              )}
          </>
        )}
        {!credentials?.creditSafe?.connected && (
          <>
            You have a monthly quota of 5 free summary reports on us. If you
            would like to obtain full credit reports, please contact us.
            <Typography variant="subtitle1">
              You have {credentials?.creditSafe?.freeReports} out of 5 summary
              reports available for this month.
            </Typography>
            <Box
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "end",
                gap: "8px",
              }}
            >
              {credentials?.creditSafe?.freeReports &&
                credentials.creditSafe?.freeReports > 0 && (
                  <Button
                    variant="contained"
                    onClick={() => {
                      setSearchDialogOpen("CreditSafe")
                    }}
                  >
                    Get Free Credit Insights
                  </Button>
                )}
            </Box>
          </>
        )}
        <Divider />
        <Box
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="subtitle1" sx={{ mb: 2 }}>
            Experian
          </Typography>
          <Box
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "1rem",
              alignItems: "center",
            }}
          >
            <img src={experian} style={{ height: "24px" }} />
          </Box>
        </Box>
        Connect your Experian account to view credit reports instantly.
        <Box
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "end",
            gap: "8px",
          }}
        >
          <Button
            variant="contained"
            onClick={() => {
              if (creditReport && creditReport.length > 0) {
                setPdfToView(creditReport[0].file)
              }
            }}
          >
            View Report
          </Button>
          {!credentials?.experian?.connected && (
            <Button variant="contained" disabled>
              Not Connected
            </Button>
          )}
          {credentials?.experian?.connected && (
            <Button
              variant="contained"
              onClick={() => {
                setSearchDialogOpen("Experian")
              }}
            >
              Begin Search
            </Button>
          )}

        </Box>
        {purchasedExperianReports && (
          <Stack
            direction="column"
            justifyContent="space-evenly"
            alignItems="stretch"
            spacing={2}
          >
            <ExperianPurchasedReportItem
              key={100}
              fileName={`Experian Report`}
              createdAt={new Date().toISOString()}
              onView={() => {
                if (creditReport && creditReport.length > 0)
                  setPdfToView(creditReport[0].file)
              }}
            />
            {purchasedExperianReports.map((report: any, index: number) => (
              <ExperianPurchasedReportItem
                key={index}
                fileName={`Experian Report ${index + 1}`}
                createdAt={report.createdAt}
                onView={() => {
                  setPdfToView(report.url)
                }}
              />
            ))}
          </Stack>
        )}
        <Divider />
        <Typography variant="subtitle1" sx={{ mb: 2 }}>
          Upload your own copy
        </Typography>
        Use this option if you have a credit report from another source.
        {renderCredit}
      </Stack>
      {showUploadDialog && (
        <DropzoneDialog
          open={showUploadDialog}
          onSave={(newFiles) => {
            execute(newFiles)
            setShowUploadDialog(false)
          }}
          acceptedFiles={["application/pdf"]}
          showPreviews={true}
          maxFileSize={32000000}
          onClose={() => {
            setShowUploadDialog(false)
          }}
          filesLimit={10}
          fileObjects={[]}
        />
      )}
      {pdfToView && (
        <PDFViewerDialog
          title="Credit Report"
          fileUrl={pdfToView}
          onClose={() => setPdfToView(undefined)}
        />
      )}
      {searchDialogOpen === "CreditSafe" && (
        <CreditSafeSearchDialog
          application={application}
          isSearchDialogOpen={!!searchDialogOpen}
          handleCloseSearchDialog={(connectId) => {
            setSearchDialogOpen(undefined)
            setConnectId(connectId || "")
            setShowInsights(connectId ? true : false)
          }}
        />
      )}

      {searchDialogOpen === "Experian" && (
        <ExperianSearchDialog
          application={application}
          isSearchDialogOpen={!!searchDialogOpen}
          handleCloseSearchDialog={(bin) => {
            setBin(bin)
            setSearchDialogOpen(undefined)
          }}
        />
      )}

      {isLoading ||
        (isLoadingExperianReport && (
          <Dialog open={true}>
            <DialogTitle sx={{ pb: 0 }}>Please wait</DialogTitle>
            <DialogContent sx={{ p: 0 }}>
              <CircularProgress />
            </DialogContent>
          </Dialog>
        ))}

      {showInsights && (
        <Dialog open={showInsights} fullWidth>
          <DialogTitle sx={{ pb: 0 }}>Credit Insights</DialogTitle>
          <DialogContent sx={{ p: 0 }}>
            {isLoading && (
              <Stack
                style={{
                  alignItems: "center",
                  padding: "16px",
                  gap: "1rem",
                }}
              >
                <CircularProgress />
                Just a moment...
              </Stack>
            )}
            {!isLoading && report && !report.pdf && (
              <>
                <Box
                  style={{
                    display: "flex",
                    width: "100%",
                    flexDirection: "row",
                    gap: "1rem",
                    padding: "16px",
                  }}
                >
                  <CreditViewWidget
                    title="Credit Rating"
                    total={`"${report.json?.report?.creditScore?.currentCreditRating?.commonValue}" ${report.json?.report?.creditScore?.currentCreditRating?.commonDescription}`}
                    icon={
                      <Iconify
                        icon="material-symbols:health-metrics"
                        width="48px"
                        sx={{ color: theme.palette.primary.main }}
                      />
                    }
                    sx={{ width: "100%", margin: "1rem 0 1rem 0" }}
                  />
                  <CreditViewWidget
                    title="Approx. Credit Limit"
                    total={`${fShortenNumber(
                      report.json?.report?.creditScore?.currentCreditRating
                        ?.creditLimit?.value,
                    )} ${report.json?.report?.creditScore?.currentCreditRating
                      ?.creditLimit?.currency}`}
                    shorten
                    icon={
                      <Iconify icon="flat-color-icons:bar-chart" width="48px" />
                    }
                    color="warning"
                    sx={{ width: "100%", margin: "1rem 0 1rem 0" }}
                  />
                  <CreditViewWidget
                    title="Legal Filings"
                    total={
                      Number(
                        report.json?.report?.negativeInformation
                          ?.legalFilingSummary?.suits,
                      ) ||
                      0 +
                        Number(
                          report.json?.report?.negativeInformation
                            ?.legalFilingSummary?.uccFilings,
                        ) ||
                      0 +
                        Number(
                          report.json?.report?.negativeInformation
                            ?.legalFilingSummary?.taxLienFilings,
                        ) ||
                      0 +
                        Number(
                          report.json?.report?.negativeInformation
                            ?.legalFilingSummary?.judgmentFilings,
                        ) ||
                      0 +
                        Number(
                          report.json?.report?.negativeInformation
                            ?.legalFilingSummary?.cautionaryUCCFilings,
                        ) ||
                      0
                    }
                    icon={<Iconify icon="octicon:law-16" width="48px" />}
                    color={"success"}
                    sx={{ width: "100%", margin: "1rem 0 1rem 0" }}
                  />
                </Box>
                <Divider />
              </>
            )}
            {!isLoading && report && report.pdf && (
              <iframe
                src={report.pdf}
                style={{
                  width: "100%",
                  height: "75vh",
                }}
              />
            )}
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setShowInsights(false)
              }}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  )
}
