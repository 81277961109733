import * as React from "react"
import { Formik } from "formik"

import {
  Backdrop,
  Box,
  CircularProgress,
  Divider,
  Typography,
  useTheme,
  Grid,
  Stack,
} from "@mui/material"

import { useParams } from "react-router-dom"
import { TradeReference } from "../types"
import { BrowserView, MobileView } from "react-device-detect"
import { Helmet } from "react-helmet-async"
import { usePatchTradeReference } from "src/queries/credit/usePatchTradeReference"
import { LoadingButton } from "@mui/lab"
import { useSnackbar } from "notistack"
import { useTradeReferences } from "src/queries/credit/useTradeReferences"
import { hotjar } from "react-hotjar"
import { convertCommaSeparatedToNumber } from "src/utils/formatNumber"
import TradeReferenceFields from "src/sections/@dashboard/credit/components/TradeReferenceFields"
import * as yup from "yup"

export default () => {
  const params = useParams()

  const { id } = params

  const { data } = useTradeReferences(undefined, id)
  const { enqueueSnackbar } = useSnackbar()

  const { execute, isLoading } = usePatchTradeReference(() => {
    enqueueSnackbar(
      "Trade reference updated. You can leave this window if all the information is up to date.",
      { variant: "success" },
    )
    hotjar.event("reference_submitted")
  })

  const theme = useTheme()

  const validationSchema = yup.object({
    accountOpenDate: yup
      .string()
      .nullable()
      .test({
        name: "null",
        exclusive: false,
        params: {},
        message: "Must be earlier than last sold date",
        test: function (value) {
          if (!this.parent.lastSoldDate) return true
          if (!value) return true
          return new Date(value) <= new Date(this.parent.lastSoldDate)
        },
      }),
    lastSoldDate: yup
      .string()
      .nullable()
      .test({
        name: "max",
        exclusive: false,
        params: {},
        message: "Must be later than account open date",
        test: function (value) {
          if (!this.parent.accountOpenDate) return true
          if (!value) return true

          return new Date(value) >= new Date(this.parent.accountOpenDate)
        },
      }),
    balance: yup
      .string()
      .nullable()
      .test({
        name: "balance",
        exclusive: false,
        params: {},
        message: "Must be less than credit limit",
        test: function (value) {
          if (!this.parent.creditLimit) return false
          if (!value) return false
          try {
            return parseFloat(value) <= parseFloat(this.parent.creditLimit)
          } catch (e) {
            return false
          }
        },
      }),
  })

  if (!data || data.length === 0) return <CircularProgress />

  return (
    <>
      <Helmet>
        <title>{"Trade Reference"}</title>
      </Helmet>
      <MobileView>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={true}
        >
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "left",
              gap: "1rem",
              padding: "10%",
            }}
          >
            <Typography variant="h1">Ah! Hello there!</Typography>
            <Typography variant="h3">
              We love your enthusiasm to use our product on your phone. We are
              working hard to bring you amazing features.
            </Typography>
            <Typography variant="h3">
              However, the application is only fillable using a laptop or a
              desktop computer at the moment.
            </Typography>
          </Box>
        </Backdrop>
      </MobileView>
      <BrowserView>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "left",
            padding: "5%",
            maxHeight: "100vh",
            overflow: "auto",
          }}
        >
          <Box
            style={{
              textAlign: "left",
              padding: "32px 10% 32px 10%",
              border: "solid",
              borderWidth: "1px",
              borderRadius: "16px",
              borderColor: theme.palette.primary.main,
            }}
          >
            <Formik
              initialValues={data[0]}
              validationSchema={validationSchema}
              validateOnBlur={false}
              validateOnChange={false}
              onSubmit={(values: Partial<TradeReference>) => {
                if (values.creditLimit != undefined) {
                  values.creditLimit = convertCommaSeparatedToNumber(
                    values.creditLimit,
                  )
                }
                if (values.balance != undefined) {
                  values.balance = convertCommaSeparatedToNumber(values.balance)
                }

                execute(values)
              }}
            >
              {(props) => (
                <Stack
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "1rem",
                  }}
                >
                  <Typography variant="h6">
                    Requested Account Details
                  </Typography>
                  <Divider />
                  <Grid container>
                    <Grid item md={8}>
                      <Typography>Account number</Typography>
                    </Grid>
                    <Grid item md={4}>
                      {data[0].accountNumber}
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item md={8}>
                      <Typography>Account Name</Typography>
                    </Grid>
                    <Grid item md={4}>
                      {data[0].legalBusinessName}
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item md={8}>
                      <Typography>Address</Typography>
                    </Grid>
                    <Grid item md={4}>
                      <Stack
                        style={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <div>{data[0].buyerAddress?.address}</div>
                        <div>{`${data[0].buyerAddress?.city} ${data[0].buyerAddress?.region}`}</div>
                        <div>{`${data[0].buyerAddress?.postCode} ${data[0].buyerAddress?.country}`}</div>
                      </Stack>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item md={8}>
                      <Typography>Contact Information</Typography>
                    </Grid>
                    <Grid item md={4}>
                      <Stack
                        style={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <div>{data[0].buyerEmail}</div>
                        <div>{data[0].buyerPhoneNumber}</div>
                      </Stack>
                    </Grid>
                  </Grid>

                  <Typography
                    component="h1"
                    variant="h5"
                    style={{ marginTop: "8px" }}
                  >
                    Trade Reference
                  </Typography>
                  <Typography variant="body2" style={{ margin: "8px 0 8px 0" }}>
                    Please provide the following information regarding the
                    account requested
                  </Typography>
                  <Divider />

                  <TradeReferenceFields
                    businessId={data?.[0]?.sellerId || undefined}
                    values={props.values}
                    setFieldValue={props.setFieldValue}
                    errors={props.errors}
                  />

                  <LoadingButton
                    onClick={() => {
                      props
                        .validateForm()
                        .then((errors) => {
                          if (Object.keys(errors).length === 0) {
                            props.submitForm()
                            return
                          } else {
                            enqueueSnackbar(
                              "Validation failed. Please double check your data.",
                              {
                                variant: "warning",
                                preventDuplicate: true,
                              },
                            )
                            return
                          }
                        })
                        .catch((err) => {
                          console.log(err)
                        })
                    }}
                    size="large"
                    loading={isLoading}
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                  >
                    Submit
                  </LoadingButton>
                </Stack>
              )}
            </Formik>
          </Box>
        </Box>
      </BrowserView>
    </>
  )
}
